* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  /* box-sizing: border-box; */
}

.main_section {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.main {
  height: 100%;
  margin-top: 18vh;
}

.text-transform-none {
  text-transform: none;
}

/* Hide the spinner for number inputs */
.account input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal_name input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fs-7 {
  font-size: 13px;
}

.max-content {
  height: max-content;
}

.color {
  color: #c32015;
}

.no-clear-icon::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

.cursor-pointer {
  cursor: pointer;
}

/* ====================== Navbar Css Start =============== */

.navbar {
  width: 100%;
  z-index: 999;
  position: fixed !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.navbar .nav-item a {
  width: max-content;
}

.navbar .nav_item_location {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.navbar .locate_input {
  display: flex;
  align-items: center;
  background-color: #eef3f4;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.navbar .locate_input span {
  width: max-content;
}

#navbarDropdown::after {
  content: none !important;
}

.dropdown-item {
  color: grey !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.dropdown-item:hover {
  color: black !important;
}

.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:focus-within {
  color: black !important;
  /* background-color: #ebedf0 !important; */
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.modal_name {
  width: 300px;
  padding: 8px;
  background-color: white;
  border-radius: 23px;
  border: 1px solid #efefef;
  margin-top: 2vh;
  outline: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.modal_name .search {
  background-color: white;
  color: black;
  border: none;
  outline: none;
  padding: 0 4px 0 8px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}

.modal_location {
  margin-top: 5vh;
}

.modal_location input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.modal_location button:hover {
  background-color: #bb2d3b;
}

.modal_location button {
  width: 300px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  padding: 5px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 23px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.modal_location button span {
  font-weight: 500;
}

.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:focus-within {
  background-color: white;
}

/* ====================== Navbar Css End =============== */

/* ====================== Product Css Start =============== */

.products .card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: none;
  position: relative;
}

.products .card .wishlist_add {
  position: absolute;
  display: flex;
  justify-content: end;
  width: 100%;
  padding: 6px;
  cursor: pointer;
}

.products .card .wishlist_add > * {
  font-size: 22px;
  color: white;
}

.products .card-title {
  font-weight: 500;
  margin-bottom: 2px;
  font-size: 17px;
}

.products .card-text {
  font-size: 14px;
  color: grey;
  margin-bottom: 0.5rem;
}

/* ====================== Product Css End =============== */

/* ====================== Product Type Css Start =============== */

.product_type .product_type_container .card {
  border: none;
}

.product_type .product_type_container {
  overflow-x: auto;
  display: flex;
  /* display: -webkit-box; */
  gap: 3rem;
  border-radius: 10px;
  /* padding: 6px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
}

.product_type .product_type_container::-webkit-scrollbar {
  display: none;
}
.product_type .product_type_container .cardd {
  flex: 0 0 auto;
  width: 250px;
}

/* ====================== Product Type Css End =============== */

/* ====================== Subscribe Css Start =============== */

.subscribe-form {
  width: 100%;
}

.subscribe-form .form-group {
  position: relative;
  margin-bottom: 0;
  border: none;
  background: #fff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.subscribe-form .form-group input {
  background: #fff !important;
  border: 1px solid transparent;
  color: black !important;
  font-size: 14px;
  font-weight: 300;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}

.subscribe-form .form-control {
  height: 52px !important;
  background: #fff !important;
  color: #000000 !important;
  font-size: 18px;
  border-radius: 0px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.subscribe-form .form-group .submit {
  border-left: 1px solid #82ae46;
  color: #fff !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  font-size: 12px;
  background: #82ae46 !important;
}

.subscribe-form .form-group input {
  background: #fff !important;
  border: 1px solid transparent;
  color: black !important;
  font-size: 14px;
  font-weight: 300;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}

/* ====================== Subscribe Css End =============== */

/* ====================== Payment Successfull Css Start =============== */

._failed {
  border-bottom: solid 4px red !important;
}

._failed i {
  color: red !important;
}

._success {
  box-shadow: 0 15px 25px #00000019;
  padding: 45px;
  width: 100%;
  text-align: center;
  margin: 40px auto;
  border-bottom: solid 4px #28a745;
}

._success i {
  font-size: 55px;
  color: #28a745;
}

._success h2 {
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}

._success p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #495057;
  font-weight: 500;
}

/* ====================== Payment Successfull Css End =============== */

.activeColor {
  background-color: #252460 !important;
  color: #fff !important;
  font-size: 1.125rem !important;
  padding: 0px 25px !important;
}

.activeColor1 {
  background-color: #dc3545 !important;
  color: #fff !important;
}

.subscribe-form .Subscribe {
  background-color: #dc3545 !important;
}

.selected {
  background-color: orange;
  color: #fff;
}

.time-slot {
  font-weight: 700;
  gap: 4px;
}

.time-gap input {
  gap: 10px;
}

.product_detail img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.product_detail video {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.product_img_parent {
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  overflow-x: auto;
  margin-top: 6px;
}

.product_img_parent::-webkit-scrollbar {
  display: none;
}

.product_img_parent img {
  max-width: 120px;
  max-height: 120px;
  cursor: pointer;
  width: 100%;
  height: auto;
}

.product_img_parent video {
  max-width: 120px;
  max-height: 120px;
  width: 120px;
  height: 120px;
  object-fit: cover;
  cursor: pointer;
  position: relative;
}

.video-container {
  position: relative;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: white;
  pointer-events: none;
}

.item-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100px;
  margin: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.item-grid {
  display: flex;
}

.item-image {
  width: 100%;
  height: 90px;
  object-fit: cover;
}

.item-details {
  padding: 3px;
}

.item-name {
  font-size: 1em;
  margin: 0;
}

.item-price {
  color: #666;
  font-size: 1em;
}
.modal_coupon {
  position: absolute !important;
  top: 32% !important;
  right: 18.5% !important;
  left: auto !important;
  width: 270px !important;
  transform: translate(-50%, -50%);
}
